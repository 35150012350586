<template>
  <div style="height: 100%;">
      <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>
    <div class="grid-form1" style="padding: 0">
      
      <div class="neworderinfo">
        <label>商品信息</label>
        <div v-if="selectedPList.length>0">
                    <div class="selected_product">
                        <table class="dataintable" >
                    <tr style="font-weight: bold">
                        <th width="50px">删除</th>
                        <th>商品名称</th>   
                        <th width="180px">商品规格</th>
                        <th width="90px">售价</th>
                        <th width="90px">剩余库存</th>
                        <th width="100px">耗损日期</th>
                        <th width="100px">耗损数量</th>
                    </tr>
                    <tr v-for="(item, index) in selectedPList" :key="index">
                        <td><label style="color:red;" @click="delSelectedProduct(item)">X</label> {{item.posn}}</td>
                        <td>{{item.pname}}</td>
                        <td>{{item.guigename+':'+item.guige}}</td>
                        <td>{{item.price}}</td>
                        <td>{{item.guigenum}}</td>
                        
                        <td>
                          <input
                            type="text"
                            class="form-control1"
                            style="width:90px;background-color:white;"
                            
                            readonly
                            v-model.trim="item.wastagedate"
                            @click.stop="showcalender($event,item)"
                          />
                        </td>
                        <td>
                            <input type="text"
                    class="form-control1"
                     v-float-only v-model="item.pbuycount" style="width:90px;background-color:white;" >
                        </td>
                    </tr>
                    
                        </table>
                    </div>
                    
                    <div  style="padding-top: 10px;text-align:right;padding-right:50px;">
          
                    
                    <button
                      class="btn btn-default"
                      type="button"
                      :disabled="submitSaving"
                      @click="neworder"
                    >
                      <i class="fa fa-save" aria-hidden="true"></i>&nbsp;
                      {{ submitSaving ? "提交中.." : "提交保存" }}
                    </button>
                  </div>
                    <div>&nbsp;&nbsp;</div>
                </div>
      </div>
    </div>
 
        <div class="orderlist" >
            <div class="order_item" >
                <div class="search_condition2">
                    <div class="order_item_title"><label
                    for="txtContractNumber"
                    >选择要核损的商品：</label
                    ></div>
                    <div class="pname">
            <label
              for="txtContractNumber"
              class="control-label"
              >商品名称</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              v-model.trim="searchpname"
              @keyup.enter="search"
            />
          </div>
          <div class="sltStyle">
            <select name="sltProductClass" v-model="productFClass" @change="setCClass">
              <option value="">请选择商品类别</option>
              <option :value="item.fcid" v-for="(item,index) in classFList" :key="index">{{item.fcname}}</option>
            </select>
            <select name="sltProductCClass" v-model="productCClass">
              <option value="">请选择二级分类</option>
              <option :value="item.ccid" v-for="(item,index) in classCList" :key="index">{{item.ccname}}</option>
            </select>
          </div>
          
          <!-- <div class="sltStyle">
            <select name="sltProductStatus" v-model="productStatus">
              <option value="">请选择产品状态</option>
              <option value="1">待上架</option>
              <option value="2">已上架</option>
              <option value="3">已下架</option>
            </select>
          </div> -->
          
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="search"
            >
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;搜索
            </button>
                </div>
                

                

                <div
                    class="tab-content"
                    style=" margin-top: 5px; padding: 0"
                    v-if="show"
                >
                    <table class="dataintable" v-if="orderList.length > 0">
                    <tr style="font-weight: bold">
                  
                        <th width="100px">商品ID</th>
                        <th width="90px">商品图片</th>   
                        <th >商品名称</th>
                        <th width="200px">所属类别</th>
                        <th width="150px">规格</th>
                        <th width="100px">价格</th>
                        <th width="100px">剩余库存</th>
                    </tr>
                    <tr v-for="(item, index) in currentpagelist"
                        :key="index"
                        @mouseover="mouseOver(index)"
                        @mouseout="mouseOut"
                        @click="screen_click(item)"
                        :class="{ actived: currentIndex == index }">
                            
                        <td>{{item.id}}</td>            
                        <td class="productinfo_head_img"><img  :src="item.imgurl"/></td>            
                        <td>{{item.pname}}</td>            
                        <td>{{item.pclass}}</td>          
                        <td>{{item.guigename+':'+item.guige}}</td>          
                        <td>{{item.price}}</td>          
                        <td>{{item.guigenum}}</td>          
                    </tr>
                    </table>
                    <div v-if="shownoresult" class="noresult">没有找到符合查询条件的商品！</div>
                    <div
                  class="pagenum_info"
                  v-if="!shownoresult"
                >
                  <span class="btmInfo" style="vertical-align: middle"
                    >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第
                    {{ currentpage }}/{{ pagecount }} 页</span
                  >
                  <div style="float: right; padding-right: 30px">
                  <button class="btn btn-default" type="button" @click="getNewPage(1)">
                    首页
                  </button>
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="getNewPage(currentpage - 1)"
                  >
                    上页
                  </button>
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="getNewPage(currentpage + 1)"
                  >
                    下页
                  </button>
                  <button
                    class="btn btn-default"
                    type="button"
                    @click="getNewPage(pagecount)"
                  >
                    尾页
                  </button>
                  <span class="btmInfo" style="margin-right: 2px">跳转到</span>
                  <input
                    type="text"
                    size="1"
                    id="txtGotoPage"
                    autocomplete="off"
                    v-int-only
                    style="margin-right: 10px; font-size: 0.9em"
                    v-model.trim="gotopage"
                  />
                  <button
                    type="button"
                    class="btn btn-default"
                    @click="getNewPage(gotopage)"
                  >
                    跳转
                  </button>
                </div>
                </div>
                
              </div>
            </div>
            
            
        </div>
       
      
    </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import Toast from "../../components/Toast";

import Calender from "../../components/vue-calendar-component";

export default {
  name: "productwastage",
  components: {
    Calender,
    Toast
  },
  data() {
    return {
        showClient:false,
        // searchedate:'',
        // searchbdate:'',
        shownoresult:false,
        // shownoresult2:true,
        orderList:[],
      orderListC: [],
      selectedPList:[],
      orderList2: [],
      clientID:'',
      currentIndex: -1,
    //   currentIndex2: -1,
      currentpage: 1,
    //   currentpage2:1,
      gotopage: "",
    //   gotopage2: "",
    //   searchpcontact: "",
      searchpname: "",
    //   paystatus:"未支付",
    //   devstatus:"未取货",
        isshowcalender: false,
      show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS:"",
      order_allnum:0,
      submitSaving: false,
      
      classFList:[],
      classCList:[],
      productFClass: "",
      productCClass: "",
      productStatus: "",
    //   base_freight: 0,
    //   freight:0,
      logedinuser:''
    };
  },
  methods: {
    // selectClient(){
    //     this.showClient=true
    // },
    clearSearch() {
      this.searchpname = ""
      this.productStatus=""
      this.productFClass=""
      this.productCClass=""
      this.classCList=[]
      // this.cancelAllList()
    },
    setCClass(){
        this.classFList.forEach((v)=>{
            if(v.fcid==this.productFClass){
                this.productCClass=""
                // this.classCList=""
                this.classCList=v.cclass
            }
        })
        // alert(this.productFClass)
    },
    screen_click(pid) {
        let tmpflag = false
        let tmpcount=1
        let tmpindex=-1

        tmpindex=this.selectedPList.indexOf(pid)
      
        if(tmpindex>=0){
            // pid.pbuycount=parseFloat(pid.pbuycount)+1
            // pid.pbuyprice=parseFloat(pid.pwsprice)*pid.pbuycount
            // Vue.set(this.selectedPList,tmpindex,pid)
            tmpflag=true
        }
        
        if(!tmpflag){
            // pid.pbuycount=1
            // pid.pbuyprice=pid.pwsprice
            this.selectedPList.push(pid)
        }
        
    },
    
    delSelectedProduct(item){
        let tmpindex=-1

        tmpindex=this.selectedPList.indexOf(item)
        
        this.selectedPList.splice(tmpindex,1)
        // this.getOrderTotalPrice()
    },
    
    
    
      mouseOver(index) {
      // alert(index)
      this.currentIndex = index;
    },
    
    mouseOut() {
      // alert(index)
      this.currentIndex = -1;
    },
    
    
      // goback() {
      //     this.$router.go(-1)
      // },
    async neworder(){
      
      let flag=true
      
      this.selectedPList.forEach((v)=>{

          if(v.wastagedate == ""){
              this.$refs.toast.toast("请输入耗损日期")
                flag= false
                return
          }
          if(v.pbuycount==""||v.pbuycount=="0"){
              this.$refs.toast.toast("请输入耗损数量")
                flag= false
                return
          }
          if(v.pbuycount>v.guigenum){
              this.$refs.toast.toast("耗损数量超库存")
                flag= false
                return
          }
          
      })
      if(flag){
        if(confirm("您确认要提交这些耗损数据吗？")){
            this.submitSaving=true

            let orderDetailInfo=this.selectedPList
            // let totalPrice=0
            this.order_allnum=0
            this.selectedPList.forEach(v=>{
              this.order_allnum+=parseInt(v.pbuycount)
            })
                
            let orderInfo={"opruser":this.logedinuser}
            const res=await this.$post(this.GLOBAL.serverAddress + "/submitHSOrder/",{orderInfo,orderDetailInfo})
            // console.log(res)
            if(res.data===true){
                this.$refs.toast.toast("耗损记录成功")
                setTimeout(()=>{
                    this.$router.go(0)
                    },1500)
                
                // this.$refs.toast.toast("备注更新成功")
            }else{
                alert(res.data)
                this.submitSaving = false
                //   this.$refs.toast.toast("提交失败请重试")
            }
          }
        }

    },
      viewOrderDetail(id){
          this.$router.push('/orderdetail?id='+id)
      },
      
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
    //   this.searchyxqdate = data;
        // console.log(this.currentDateS)
      // if(this.currentDateS==='b'){
      //     this.searchbdate=data
      // }else{
      //     this.searchedate=data
      // }
      let tmpindex=-1

      tmpindex=this.selectedPList.indexOf(this.currentDateS)
      this.selectedPList[tmpindex].wastagedate=data
      console.log(this.selectedPList)
      // this.isshowcalender = false;
    },
    showcalender(e,v) {
      var divC = this.$refs.curDiv;
      // console.log(v)
      this.currentDateS=v
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },

    // clearSearch() {
    //   this.searchpname = ""
    //   this.searchpcontact = ""
    //   this.searchedate=""
    //     this.searchbdate=""
    //     this.devstatus="未取货"
    //     this.paystatus="未支付"
    // },
    getNewPage(pagenum) {
      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
        // console.log(this.pages)
        // return this.pages[this.currentpage - 1];
      }
    },
    
    
    async getClientList(){
        const res2 = await this.$get(this.GLOBAL.serverAddress + "/getAllProductClass/",{});
      console.log(res2);

      if (res2.status === 200) {
        this.classFList = res2.data;
      }

    },
    
    async search() {
      this.currentpage=1
      let tmpClass=""
      if(this.productCClass!=''){
          tmpClass=this.productCClass
      }else{
          tmpClass=this.productFClass
      }
      const res = await this.$get(this.GLOBAL.serverAddress + "/searchAllProductForOrder/", 
      // const res = await this.$get(this.GLOBAL.serverAddress + "/getCurrentFreight/", 
      {'pname':this.searchpname,'pclass':tmpClass});
    //   console.log(res);
        
      if (res.status === 200) {
        this.orderList = res.data;
        this.show=true
        if(this.orderList.length>0){
            this.shownoresult=false
        }else{
            this.shownoresult=true
        }
      }

    },
  },
  computed: {
    
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    
    pagecount() {
      return Math.ceil(this.orderList.length / 20);
    },
    
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 20);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
    
  },
  mounted() {
    this.haveRights('|0105|')
    // this.search();
    this.getClientList()
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
    this.logedinuser=sessionStorage.getItem("loginuser")
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
};
</script>

<style scoped lang="less">
.neworderinfo{
  margin:20px;
  color: #999;
  .ni_user{
    padding-left: 30px;
    label{
      padding-right: 50px;
      font-weight:normal;
    }
  }
  
}

.search_condition2 {
    display: flex;
    // white-space: nowrap;
    // justify-content:center;;
    align-items: center;
    
    padding:10px;
    // padding-bottom: 20px;
  .pname2 {
    //   display: flex;
    // // border: 1px solid red;
    // // flex: 2;
    // align-items: center;
    padding-left: 10px;
    text-align: right;
    color: #999;
  }

  .pname_value2 {
    // flex: 2;
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    // padding: 0.5em 1em;
    color: #999;
    }
  }
  .client_search{
      padding-left: 20px;
      display: flex;
      align-items: center;
  }
  .bdate{
      padding-left: 100px;
    //   flex:1;
  }
  .sltStyle {
    
    padding-left: 5px;
    color:#999;
    select{
      border: 1px solid #E9E9E9;
    font-size: 0.9em;
    outline: none;
    padding: 0.5em 1em;
    color: #999;
    }
  }
}
.selected_product{
    // padding: 10px;
}
.search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content:center;
    align-items: center;
    text-align: right;
    padding:10px;
  .pname {
    //   display: flex;
    // border: 1px solid red;
    flex: 1;
    padding-right: 5px;
  }

  .pname_value {
    flex: 3;
    // padding-left: 5px;
  }

  .cname {
    flex: 1;
    padding-right: 5px;
  }

  .cname_value {
    flex: 2;
  }
  
  .bdate {
    flex: 1;
    padding-right: 5px;
  }

  .bdate_value {
    flex: 1;
  }

  .edate {
    //   border: 1px solid green;
    flex: 1;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .edate_value {
    flex: 1;
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_title{
        padding: 10px;
    }
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      .order_item_briefinfo_ordernum {
        padding-left: 20px;
        flex: 1;
        display: flex;
        .dkxd_c{
          padding-left:10px;
          color:red;
        }
      }

      .order_item_briefinfo_orderdate {
        flex: 1;
      }
      .order_item_briefinfo_orderpay {
        flex: 1;
        text-align: center;
      }
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 4;
        display: flex;
        .orderprice {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        .orderstatus {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info{
    background:#fff;
    height: 50px;
    margin-top:20px;
    padding: 10px; color: #6c757d;
    // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}
.productinfo_head_img{
            flex:2;
            img {
            width: 90%;
            max-height: 50px;
            object-fit: contain;
          }
        }
.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>